import React, { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import {useForm} from 'react-hook-form';
import { navigate } from "gatsby"
import CheckoutForm from "../components/checkoutForm"
import { addPaidWithInvoice } from "../utils/fire"
import { getErrorText } from "../utils/globalFunctions"
import './pay-with-invoice.scss';
import NavigationBar from "../components/navigation_bar"
const PayWithInvoice = () => {
  const {register,handleSubmit,formState:{errors}}=useForm();
  const [clientToken, setClientToken] = useState<string | null>();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [amount,setAmount]=useState(-1);
  useEffect(() => {
    setToken();
  }, [])

  async function setToken() {
    const response = await fetch(
      "https://us-central1-tapl-solar.cloudfunctions.net/braintreeFunction/getToken"
    )
    const responseJSON = await response.json()
    const clientToken2 = responseJSON.clientToken
    if (clientToken2) setClientToken(clientToken2)
  }
  function onSuccess({amount,invoiceNumber}:{amount:number;invoiceNumber:string}){
    addPaidWithInvoice({amount,invoiceNumber}).then(()=>{
      navigate('/payment-success');
    });
  }
  function onSubmit({amount}:{amount:number;invoiceNumber:string}){
    setAmount(amount)
    setShowPaymentModal(true);
  }

  return (
    <div className="">
      <Modal
        centered
        onHide={() => setShowPaymentModal(false)}
        show={showPaymentModal}
      >
        <Modal.Header closeButton>Payment Method</Modal.Header>
        <Modal.Body>
          {clientToken&&amount!==-1 && (
            <CheckoutForm
              paymentOptions={{
                clientToken,
                price:amount,
              }}
              onSuccessfulPayment={handleSubmit(onSuccess)}
              active={true}
            />
          )}
        </Modal.Body>
      </Modal>
      <NavigationBar current="/home" />
      <div className="payWithInvoiceContainer container">
        <div className="h1 font-weight-light">Pay With Invoice Number</div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="formGroup">
            <Form.Label className="formLabel">Invoice Number / Memo</Form.Label>
            <Form.Control className="formInput" {...register("invoiceNumber",{required:true})} placeholder="Invoice Number / Memo"/>
            <Form.Text className="small text-danger">
              {getErrorText(errors.invoiceNumber?.type, errors.invoiceNumber?.message)}
            </Form.Text>
          </Form.Group>
          <Form.Group className="formGroup">
            <Form.Label className="formLabel">Amount ($)</Form.Label>
            <Form.Control className="formInput" type="number" {...register("amount",{valueAsNumber:true,required:true})} placeholder="Amount ($)"/>
            <Form.Text className="small text-danger">
              {getErrorText(errors.amount?.type, errors.amount?.message)}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Button className="siteButton" type="submit">Pay</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  )
}

export default PayWithInvoice
